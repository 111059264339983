import React, { useState } from "react";
import arrowLeft from "../../../static/images/home/achievements/arrow-left.svg";
import arrowLeftDone from "../../../static/images/home/achievements/arrow-left-done.svg";
import arrowRight from "../../../static/images/home/achievements/arrow-right.svg";
import arrowRightDone from "../../../static/images/home/achievements/arrow-right-done.svg";
import { Link } from "gatsby";
import achievements1 from "../../../static/images/home/achievements/1.jpg";
import achievements2 from "../../../static/images/home/achievements/2.jpg";
import achievements3 from "../../../static/images/home/achievements/3.jpg";
import achievements4 from "../../../static/images/home/achievements/4.jpg";
import achievements5 from "../../../static/images/home/achievements/5.jpg";
import styled from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import BlogPostCardSlider from "./BlogPostCardSlider";

const Achievements = () => {
  const images = [
    {
      id: "one",
      img: achievements1,
      description:
        "Medad Technology's ultrasonic delivery system demonstrates superior performance in latest clinical study.",
    },
    {
      id: "two",
      img: achievements3,
      description:
        "Carbonyl emissions are below limits of detection with ultrasound technology for nicotine delivery, reducing potential harm for adult smokers.",
    },
    {
      id: "three",
      img: achievements2,
      description:
        "Medad Technology's commitment to innovation is reflected in its portfolio of 331 patents and the development of 4 proprietary chips, accounting for 39% of all IP applications from the UAE.",
    },
    {
      id: "four",
      img: achievements4,
      description:
        "Medad Technology harnesses the innovative spirit of the UAE by obtaining medical license for local R&D and manufacturing facility.",
    },
    // {
    //     id: "five",
    //     img: achievements5,
    //     description: "Medad Technology is now studying potential solutions for electric vehicles battery issues."
    // }
  ];

  return (
    <AchievementsStyle>
      <BlogPostCardSlider>
        <BlogPostCard img={achievements1}>
          <Text>{images[0].description}</Text>
        </BlogPostCard>
        <BlogPostCard img={achievements3}>
          <Text>{images[1].description}</Text>
        </BlogPostCard>
        <BlogPostCard img={achievements2}>
          <Text>{images[2].description}</Text>
        </BlogPostCard>
        <BlogPostCard img={achievements4}>
          <Text>{images[3].description}</Text>
        </BlogPostCard>
        {/* <BlogPostCard img={achievements5}><Text>{images[4].description}</Text></BlogPostCard> */}
      </BlogPostCardSlider>
    </AchievementsStyle>
  );
};

export default Achievements;

const AchievementsStyle = styled.div`
  position: relative;
  //  min-height: 100vh;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    min-height: auto;
  }
`;

const BlogPostCard = styled.div`
  position: relative;
  width: calc(80vw);
  height: 70vh;
  width: 90vw;
  // min-height: 70rem;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: calc(100vw);
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    width: 60vw;
  }
`;

const Text = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 3rem;
  padding: 0 10rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    left: 0;
    padding: 1rem 2.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    font-family: Sequel55;
    font-size: 2.6rem;
  }
`;
