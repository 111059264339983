import React, {useCallback, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import arrowLeft from "../../../static/images/home/achievements/arrow-left.svg";
import arrowRight from "../../../static/images/home/achievements/arrow-right.svg";

import useIdle from "./useIdle";

const BlogPostCardSlider = ({children}) => {
    const [visibleSlide, setVisibleSlide] = useState(0);
    const [targetSlide, setTargetSlide] = useState(0);
    const wrapperRef = useRef(null);
    const targetSlideRef = useRef(null);

    const scrollToTargetSlide = useCallback(() => {

        const targetSlide = targetSlideRef.current;
        const wrapper = wrapperRef.current;
        if (wrapper && targetSlide) {
            wrapper.scrollTo({
                top: 0,
                left: targetSlide.offsetLeft,
                behavior: "smooth"
            });
        }
    }, []);

    const finishScrolling = useCallback(() => {
        setTargetSlide(visibleSlide);
        scrollToTargetSlide();
    }, [visibleSlide, scrollToTargetSlide]);
    const touchScroll = useIdle({timeout: 500, onIdle: null});

    const moveLeft = useCallback(targetSlide => Math.max(0, targetSlide - 1), []);
    const moveRight = useCallback(
        targetSlide => Math.min(targetSlide + 1, children.length - 1),
        [children]
    );

    const handleScroll = useCallback(() => {
        let {width} = wrapperRef.current.getBoundingClientRect();
        let {scrollLeft} = wrapperRef.current;

        setVisibleSlide(Math.round(scrollLeft / width));
        touchScroll();
    }, [touchScroll]);

    useEffect(scrollToTargetSlide, [targetSlide]);

    return (
        <Div id="trap" tabIndex="0">
            <Head>
                <Title>HIGHLIGHTS</Title>
                <Buttons>
                    <LeftButton onClick={() => setTargetSlide(moveLeft)}></LeftButton>
                    <RightButton onClick={() => setTargetSlide(moveRight)}></RightButton>
                </Buttons>
            </Head>
            <Wrapper onScroll={handleScroll} ref={wrapperRef}>
                {children.map((child, i) => (
                    <Slide
                        key={`slide-${i}`}
                        ref={i === targetSlide ? targetSlideRef : null}
                    >
                        {child}
                    </Slide>
                ))}
            </Wrapper>
        </Div>
    );
};

export default BlogPostCardSlider;

const Div = styled.div`
  outline: none;
`

const Head = styled.div`
  display: flex;
  padding: 0rem 12rem;
  justify-content: space-between;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 0rem 2.4rem 0rem 2.4rem;
    flex-direction: column;
    margin-bottom: 2.8rem;
  }
  @media ${({theme}) => theme.breakpoints.small_height} {
    padding: 0rem 15vw;
  }
`

const Title = styled.h2`
  font-size: 3.2rem;
  margin-bottom: 2.8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    font-size: 2rem;
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 2rem;
`

const LeftButton = styled.button`
  height: 6rem;
  width: 6rem;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  background-image: url(${arrowLeft});
  background-size: contain;
  cursor: pointer;
`

const RightButton = styled.button`
  height: 6rem;
  width: 6rem;
  -webkit-tap-highlight-color: transparent;
  margin-left: 1rem;
  background-color: transparent;
  background-image: url(${arrowRight});
  background-size: contain;
  cursor: pointer;
`


const SlideLink = styled.span`
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 4rem;

  background: ${props => (props.isActive ? "blue" : "red")};
  color: white;
`;

const Wrapper = styled.div`
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: none;
  gap: 6rem;
  margin-top: 1rem;
`;

const Slide = styled.div``;

