import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";

// import aImg from "../../../static/images/technologies/a.jpg";
// import bImg from "../../../static/images/technologies/b.jpg";
// import cImg from "../../../static/images/technologies/c.jpg";
// import dImg from "../../../static/images/technologies/d.jpg";
import ultrasonicImg from "../../../static/images/technologies/pod_device.png";
import ButtonStyled from "../library/Button.js";

const OurTechnologies = () => {
  return (
    <OurTechnologiesStyle>
      <Description>
        <Action>
          <Title>WHAT WE BUILD</Title>
          {/*<ButtonStyled desktop primary type={'button'} onClick={() => navigate('/technologies')} label={'See all'}/>*/}
          <Button desktop>
            <ButtonStyled
              primary
              type={"button"}
              onClick={() => navigate("/what-we-build")}
              label={"See all"}
            />
          </Button>
        </Action>
        {/* <p>Medad Technology builds technologies and turnkey solutions - we design and manufacture our own
                    Integrated Chips (IC) and Printed Circuit Boards (PCB), and we develop our own firmware and software
                    to deliver customized systems and solutions.</p> */}
        <Button mobile>
          <ButtonStyled
            primary
            type={"button"}
            onClick={() => navigate("/what-we-build")}
            label={"See all"}
          />
        </Button>
      </Description>
      <Gallery>
        <LeftColumnStyle>
          <SmallImage
            img={ultrasonicImg}
            imgMobile={ultrasonicImg}
            className={"a"}
          />
          {/* <p>End-to-end design of ICs and PCBs</p> */}
          {/* <SmallImage img={aImg} imgMobile={aImg} className={"a"} /> */}
          {/* <p>Ultrasound technology for cell lysing precision</p> */}
        </LeftColumnStyle>
        <RightColumnStyle>
          {/* <SmallImage img={cImg} imgMobile={bImg} className={'c'}/>
                    <p>Ultrasound technology mechanism delivering safe and effective inhalation for therapeutic
                        treatments and safe recreation</p> */}
          <p>
            Medad Technology's ultrasonic delivery system is built on 3
            proprietary electronic components and an advanced in-house developed
            software. It consists of a Bluetooth enabled device, a disposable
            drug-containing pod and a mobile app. The app monitors usage data
            for dose control and behaviourial therapy support.
            <br />
            <br />
            The core techology works under a Resonant Drive System (RDS) method,
            for which the natural frequency-finding electronics and algorithm
            were developed. The algorithm works seamlessly with the electronics
            to form a feedback loop that looks for the resonant point at the
            start of each inhalation, for optimal aerosol delivery efficiency.
          </p>
          {/* <BigImage img={dImg} className={'d'}/> */}
          {/* <p>
            <br />
            <br />
            The core techology works under a Resonant Drive System (RDS) method,
            for which the natural frequency-finding electronics and algorithm
            were developed. The algorithm works seamlessly with the electronics
            to form a feedback loop that looks for the resonant point at the
            start of each inhalation, for optimal aerosol delivery efficiency.
          </p> */}
        </RightColumnStyle>
      </Gallery>
    </OurTechnologiesStyle>
  );
};

export default OurTechnologies;

const OurTechnologiesStyle = styled.div`
  padding: 5rem 12rem 6rem 12rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 4.8rem 2.4rem 4.8rem 2.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 5rem 15vw 0rem 15vw;
    //padding: 0;
  }
`;
const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 3.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;

  p {
    max-width: 49.7rem;
    font-family: ${({ theme }) => theme.fonts.sequel45};
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.4rem;
    @media ${({ theme }) => theme.breakpoints.mobile} {
      font-size: 1.6rem;
      max-width: none;
      font-family: ${({ theme }) => theme.fonts.sequel45};
    }
    @media ${({ theme }) => theme.breakpoints.small_height} {
      //  font-size: 2rem;
    }
  }
`;

const Action = styled.div`
  max-width: 48.1rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    max-width: none;
  }
`;

const Gallery = styled.div`
  // margin-top: 14.5rem;
  min-height: 30vh;
  display: flex;
  gap: 12rem;
  // max-width: 115.7rem;
  padding-left: 10.7rem;
  padding-right: 10.7rem;
  margin-right: auto;
  margin-left: auto;
  flex-wrap: wrap;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 0rem;
    margin-top: 3rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  p {
    margin-top: 10.9rem;
    font-family: Sequel55;
    line-height: 2.4rem;
    font-size: 1.8rem;
    // text-shadow: 0 4px 4px rgba(0, 0, 0, .25);
    @media ${({ theme }) => theme.breakpoints.mobile} {
      margin-top: 3rem;
    }
  }
`;

const LeftColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RightColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .d {
    margin-top: 10.9rem;
    @media ${({ theme }) => theme.breakpoints.mobile} {
      margin-top: 5.5rem;
    }
  }
`;

const BigImage = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 58rem;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    height: 30.8rem;
  }
`;

const Button = styled.div`
  width: 100%;
  display: ${(props) => (props.desktop ? "block" : "none")};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 2rem;
    display: ${(props) => (props.desktop ? "none" : "block")};
  }
`;

const SmallImage = styled.div`
  margin-top: 5rem;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 35.5rem;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    background-image: url(${(props) => props.imgMobile});
    height: 30.8rem;
    margin-top: 5.5rem;
  }
`;
