import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";
import ButtonStyled from "../library/Button.js";

const Sector = () => {
  const [index, setIndex] = useState(0);
  const { strapiHomePage } = useStaticQuery(query);

  const sectors = [
    {
      title: "Consumer HealthTech",
      description:
        "Utilizing ultrasound to remove the need for heat in nicotine delivery, to provide adult smokers with a potentially reduced-harm alternative.",
      link: "sectors",
    },
    {
      title: "Pharmaceutical HealthTech",
      description:
        "Improving the efficacy of drug delivery through inhalation by controlling the aerosol particle size to target the deepest region of the lungs.",
      link: "sectors",
    },
    // {
    //   title: "FINTECH",
    //   description:
    //     "Building the banks of the future through open banking, retail apps, and AI-powered bank management.",
    //   link: "fintech",
    // },
    {
      title: "Power Management",
      description:
        "Streamlining power management with advanced proprietary electronics and algorithms for enhanced functionality to optimise aerosol delivery.",
      link: "sectors",
    },
  ];

  return (
    <SectorStyle>
      <Main>
        <Title>WHERE WE FOCUS</Title>
        <Button desktop>
          <ButtonStyled
            primary
            type={"button"}
            onClick={() => navigate("/sectors")}
            label={"See all"}
          />
        </Button>
      </Main>
      <Carousel>
        <List>
          {sectors.map((sector, key) => {
            return (
              <ListItemMobile onClick={() => setIndex(key)} key={key}>
                <span className={index === key ? "active" : ""}>
                  {sector.title}
                </span>
              </ListItemMobile>
            );
          })}

          {sectors.map((sector, key) => {
            return (
              <ListItem
                onClick={() => navigate(`/sectors`)}
                onMouseEnter={() => setIndex(key)}
                key={key}
              >
                <span className={index === key ? "active" : ""}>
                  {sector.title}
                </span>
              </ListItem>
            );
          })}
        </List>
        <ImgMobile
          index={index}
          onMouseEnter={() => navigate(`/sector/${sectors[index].link}`)}
        >
          <P>{sectors[index].description}</P>
        </ImgMobile>
        <Img index={index}>
          <P>{sectors[index].description}</P>
        </Img>
      </Carousel>
      <Button mobile>
        <ButtonStyled
          primary
          type={"button"}
          onClick={() => navigate("/sectors")}
          label={"See all"}
        />
      </Button>
    </SectorStyle>
  );
};

const SectorStyle = styled.div`
  overflow: hidden;
  padding: 10rem 12rem 10rem 12rem;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #bcdee5;
  justify-content: space-between;
  .active:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.3rem;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.secondary};
    transition: 0.25s linear;
    visibility: visible;
    transform: scaleX(1);
  }

  .active {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 4.8rem 0rem 3.8rem 0rem;
  }

  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 10rem 15vw 10rem 15vw;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 0rem 2.4rem;
    margin-bottom: 2rem;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  height: 50vh;
  justify-content: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: none;
    height: auto;
  }
`;
const ListItem = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.8rem;
  padding: 2.3rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  width: fit-content;

  span {
    white-space: nowrap;
  }

  span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.3rem;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.secondary};
    visibility: hidden;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  span:hover:before,
  span:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }

  span:hover {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-right: 3rem;
    margin-bottom: 3rem;
    display: none;
  }
`;

const ListItemMobile = styled.p`
  margin-left: 1.6rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding: 1.5rem 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  position: relative;
  width: fit-content;

  span {
    white-space: nowrap;
  }

  span:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.3rem;
    bottom: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.secondary};
    visibility: hidden;
    transform: scaleX(0);
    transition: 0.25s linear;
  }

  span:active:before,
  span:focus:before {
    visibility: visible;
    transform: scaleX(1);
  }

  span:active {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin-right: 3rem;
    margin-bottom: 3rem;
  }

  @media ${({ theme }) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
`;

const Title = styled.h2`
  font-size: 3.2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2rem;
  }
`;

const Button = styled.div`
  display: ${(props) => (props.desktop ? "block" : "none")};
  @media ${({ theme }) => theme.breakpoints.mobile} {
    margin: 0rem 2.4rem;
    display: ${(props) => (props.desktop ? "none" : "block")};
  }
`;

const Carousel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const Img = styled.div`
  background-image: url(images/sector/${({ index }) => index}.jpg);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  min-height: 400px;
  width: 50%;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: none;
  }
`;

const ImgMobile = styled.div`
  background-image: url("images/sector/${({ index }) => index}.jpg");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  height: 60vh;
  width: auto;
  margin: 0rem 2.4rem;
  margin-bottom: 3rem;
  @media ${({ theme }) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
`;

const P = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: Sequel65;
  font-size: 2rem;
  line-height: 2.7rem;
  // max-width: 46.2rem;

  position: absolute;
  bottom: 2.4rem;
  // left: 2.4rem;
  padding: 0 2.4rem;
`;

export default Sector;

const query = graphql`
  query {
    strapiHomePage {
      title
      subtitle
    }
  }
`;
