import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const Statement = () => {
  const { strapiHomePage } = useStaticQuery(query);

  return (
    <StatementStyle>
      <Method>Mission</Method>
      <h3>
        <span>
          Medad Technology is dedicated to developing novel therapeutic
          solutions that are smarter, safer and more effective for the
          prevention and treatment of chronic conditions. We are committed to
          improving public health and quality of life through scientific
          excellence and integrity.{" "}
          <HighlightText>
            We are not just imagining the possibilities. We are building them.
          </HighlightText>
        </span>
      </h3>
    </StatementStyle>
  );
};

const StatementStyle = styled.div`
  padding: 10rem 20rem 10rem 20rem;
  min-height: calc(100vh);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    11deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(149, 144, 217, 0.5) 100%
  );
  @media ${({ theme }) => theme.breakpoints.mobile} {
    justify-content: flex-start;
    padding: 8.4rem 2.4rem 8.4rem 2.4rem;
    flex-wrap: wrap;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 10rem 15vw 10rem 15vw;
    min-height: 90rem;
  }

  h3 {
    span {
      display: block;
    }
  }
`;

const HighlightText = styled.mark`
  color: ${({ theme }) => theme.colors.secondary};
  background-color: transparent;
`;

const Method = styled.p`
  color: ${({ theme }) => theme.colors.tertiary};
  padding-right: 15rem;
  font-size: 2.6rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding-right: 0rem;
    margin-bottom: 3.2rem;
  }
`;

export default Statement;

const query = graphql`
  query {
    strapiHomePage {
      title
      subtitle
    }
  }
`;
