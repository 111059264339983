import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, navigate } from "gatsby";
import bg from "../../../static/images/home/bg-whoweare.svg";
import ButtonStyled from "../library/Button.js";

const WhoWeAre = () => {
  const { strapiHomePage } = useStaticQuery(query);

  return (
    <WhoWeAreStyle>
      <Left></Left>
      <Right>
        <Title>Who We Are</Title>
        <Description>
          Medad Technology stands at the forefront of HealthTech innovation,
          having invested 4 years and $150 million in R&D to develop an
          ultrasonic delivery system that revolutionizes inhalation technology.
          Our system overcomes the limitations of traditional aerosol delivery,
          offering groundbreaking applications in both Consumer HealthTech and
          Pharmaceutical HealthTech sectors.
        </Description>
        <Button>
          <ButtonStyled
            primary
            type={"button"}
            onClick={() => navigate("/who-we-are")}
            label={"Learn More"}
          />
        </Button>
      </Right>
    </WhoWeAreStyle>
  );
};

const WhoWeAreStyle = styled.div`
  padding-bottom: 10.7rem;
  padding-top: 10.7rem;
  //min-height: calc(100vh);
  min-height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  color: ${({ theme }) => theme.colors.primary};
  justify-content: center;
  align-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 6rem 2.4rem 2.5rem 2.4rem;
  }
`;

const Left = styled.div`
  //min-height: 120vh;
  height: 100%;
  min-height: 120vh;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;

  background-attachment: fixed;
  background-position: center;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
    min-height: 60vh;
    background-attachment: initial;
  }
`;

const Button = styled.div`
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 100%;
  }
`;

const Right = styled.div`
  padding: 15rem;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding: 3rem 0 0 0;
  }
  @media ${({ theme }) => theme.breakpoints.small_height} {
    padding: 15rem 10vw;
  }
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.tertiary};
  font-size: 2.6rem;
  display: flex;
  flex-direction: column;
`;

const Description = styled.h4`
  padding: 3.2rem 0;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.sequel45};
  font-weight: 400;
  line-height: 2.4rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    font-family: ${({ theme }) => theme.fonts.sequel45};
  }
`;

export default WhoWeAre;

const query = graphql`
  query {
    strapiHomePage {
      title
      subtitle
    }
  }
`;
