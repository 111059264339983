import {graphql, useStaticQuery} from "gatsby";
import React, {useEffect} from 'react'

import Hero from "../components/hero/Hero.js";
import Statement from "../components/statement/Statement.js";
import Achievements from "../components/achievements/Achievements.js";
import StayUpdated from "../components/stay-updated/StayUpdated.js";
import WhoWeAre from "../components/whoweare/Whoweare.js";
import Sector from "../components/sector/Sector.js";
import Layout from '../components/layout/Layout'
import Seo from '../components/seo'
import OurTechnologies from "../components/our-technologies/OurTechnologies";
import scrollTo from 'gatsby-plugin-smoothscroll';

const HomePage = (props) => {
    const {strapiHomePage} = useStaticQuery(query);
    useEffect(() => {
        scrollTo("#hero-animation");
    });

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiHomePage.metaTitle}
                description={strapiHomePage.metaDescription}
                keywords={strapiHomePage.metaKeywords}
            />
            <Hero path={props.location.pathname}/>
            <Statement />
            <Achievements />
            <WhoWeAre />
            <Sector />
            <OurTechnologies />
            <StayUpdated />
        </Layout>
    )
}

export default HomePage

const query = graphql`
    query {
        strapiHomePage {
            metaTitle
            metaDescription
            metaKeywords
        }
    }
`;
