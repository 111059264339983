import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import videoHome from "../../../static/video/Homepage-animation.mp4";
import MenuLeft from "../menu-left/MenuLeft.js";
import logo from "../../../static/images/hero/image/logo-2.svg";
import scroll from "../../../static/images/hero/image/Scroll.json";
import Lottie from "lottie-react";

const Hero = ({ path }) => {
  const { strapiHomePage } = useStaticQuery(query);

  useEffect(() => {
    playAnimation();
  });

  const playAnimation = () => {
    document.getElementById("hero-animation").style =
      "@-webkit-keyframes fadeinHomeText {0% {top:100px;} 80% {top:100px;} 100% {top: 0;}}";
  };

  return (
    <HeroWrapper id={"hero-animation"}>
      <video autoPlay muted loop playsInline id="myVideo">
        <source src={videoHome} type="video/mp4" />
      </video>
      <HeroStyleMobile>
        <Logo></Logo>
        <Mantra>Building Possibilities</Mantra>
        <LottieWrapper>
          <Lottie
            id="lottie-loading"
            className="lottie-loading"
            animationData={scroll}
            loop={true}
            autoPlay={true}
          />
        </LottieWrapper>
      </HeroStyleMobile>
      <HeroStyle>
        <MenuWrapper>
          <MenuLeft path={path} />
        </MenuWrapper>
        <Title>
          <LottieWrapper>
            <Lottie
              id="lottie-loading"
              className="lottie-loading"
              animationData={scroll}
              loop={true}
              autoPlay={true}
            />
          </LottieWrapper>
          <h1>
            <div>
              <span>Building</span>
            </div>
            <div>
              <span>Possibilities</span>
            </div>
            <div></div>
            <div></div>
            {/* <div>
              <span>IMPROVING PUBLIC</span>
            </div>
            <div>
              <span>HEALTH & QUALITY </span>
            </div>
            <div>
              <span>OF LIFE.</span>
            </div> */}
          </h1>
        </Title>
      </HeroStyle>
    </HeroWrapper>
  );
};

const HeroStyleMobile = styled.div`
  padding: 6rem 2.4rem;
  min-height: calc(100vh);
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-item: center;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.mobile_reverse} {
    display: none;
  }
`;

const HeroWrapper = styled.div`
  @keyframes fadeinHomeText {
    0% {
      top: 100px;
    }
    70% {
      top: 100px;
    }
    100% {
      top: 0px;
    }
  }
  @-moz-keyframes fadeinHomeText {
    /* Firefox */
    0% {
      top: 100px;
    }
    70% {
      top: 100px;
    }
    100% {
      top: 0px;
    }
  }
  //@-webkit-keyframes fadeinHomeText { /* Safari and Chrome */
  //  0% {
  //    top: 100px;
  //  }
  //  80% {
  //    top: 100px;
  //  }
  //  100% {
  //    top: 0px;
  //  }
  //}
  @-o-keyframes fadeinHomeText {
    /* Opera */
    0% {
      top: 100px;
    }
    70% {
      top: 100px;
    }
    100% {
      top: 0px;
    }
  }

  height: 110vh;
  min-height: 428px;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 130vh;
    min-height: 450px;
    // z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    -webkit-transition: 1s opacity;
    transition: 1s opacity;
    object-fit: cover;
  }
`;

const LottieWrapper = styled.div`
  #lottie-loading {
    z-index: 1;
    animation: fadein 3s;
    -moz-animation: fadein 3s; /* Firefox */
    -webkit-animation: fadein 3s; /* Safari and Chrome */
    -o-animation: fadein 3s; /* Opera */
    position: absolute;
    left: -24px;
    bottom: -24px;
    max-height: 150px;
    width: 5%;

    svg {
      height: unset;
    }

    @media ${({ theme }) => theme.breakpoints.mobile} {
      bottom: 64px !important;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      width: 5%;
    }
  }

  @media ${({ theme }) => theme.breakpoints.mobile} {
    align-self: center;
  }
`;

const Logo = styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-size: contain;
  height: 5.1rem;
  width: 16.8rem;
`;

const Mantra = styled.h1`
  font-size: 2.6rem;
  line-height: 3.5rem;
  color: white;
  text-transform: uppercase;
`;

const HeroStyle = styled.div`
  padding: 10rem 12rem 3rem 12rem;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    display: none !important;
  }
  // @media ${({ theme }) => theme.breakpoints.small_height} {
  //     padding: 10rem 15vw 3rem 15vw;
  // }
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  transform: translateY(0);

  h1 {
    width: 55rem;
    div {
      width: 100%;
      height: 6rem;
      @media ${({ theme }) => theme.breakpoints.small_height} {
        height: 4rem;
      }
      position: relative;
      overflow: hidden;
      span {
        animation: fadeinHomeText 2.8s;
        -moz-animation: fadeinHomeText 2.8s; /* Firefox */
        -webkit-animation: fadeinHomeText 2.8s; /* Safari and Chrome */
        -o-animation: fadeinHomeText 2.8s; /* Opera */
        position: absolute;
        display: inline-block;
        @media ${({ theme }) => theme.breakpoints.small_height} {
          font-size: 3.5rem;
          line-height: 4.5rem;
        }
      }
    }
  }
`;
const MenuWrapper = styled.div`
  z-index: 5;
  transform: translateY(0);
`;

export default Hero;

const query = graphql`
  query {
    strapiHomePage {
      title
      subtitle
    }
  }
`;
